/* eslint-disable react/no-array-index-key */
import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, useMediaQuery, useTheme, alpha,
} from '@mui/material';
import Carousel from 'react-multi-carousel';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import 'react-multi-carousel/lib/styles.css';
import Youtube from 'react-lazyload-youtube';
import 'react-lazyload-youtube/dist/index.css';
import Image from 'next/image';
import { Thumbnails, SliderDots } from 'views/common/components';

const PREFIX = 'HeroGallery';

const classes = {
  img: `${PREFIX}-img`,
  carousel: `${PREFIX}-carousel`,
  fullScreen: `${PREFIX}-fullScreen`,
  imgContainer: `${PREFIX}-imgContainer`,
  videoContainer: `${PREFIX}-videoContainer`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(Box)(({
  theme, videoWidth, videoHeight, height,
}) => ({
  height,
  [`& .${classes.img}`]: {
    width: '100%',
    objectFit: 'cover',
  },

  [`& .${classes.carousel}`]: {
    position: 'relative',
    height: '100%',
    '& > ul': {
      listStyle: 'none',
      margin: '0',
      padding: '0',
      display: 'flex',
    },

    '& .arrow': {
      position: 'absolute',
      top: '40%',
      width: theme.spacing(6),
      height: theme.spacing(6),
      borderRadius: '50%',
      backgroundColor: 'rgba(48, 48, 48, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.common.fogWhite,
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },

      '&.arrowLeft': {
        left: theme.spacing(1.5),
        [theme.breakpoints.up('md')]: {
          left: theme.spacing(3),
        },
      },

      '&.arrowRight': {
        right: theme.spacing(1.5),
        [theme.breakpoints.up('md')]: {
          right: theme.spacing(3),
        },
      },

      '& > svg': {
        fontSize: theme.typography.pxToRem(32),
      },
    },
  },
  '& .carousel-button-group ': {
    width: theme.spacing(10),
    margin: 'auto',
  },
  [`& .${classes.fullScreen}`]: {
    height: '100%',
    backgroundColor: alpha(theme.palette.secondary.dark, 0.7),
    '& > ul': {
      height: '100%',
      '& li': {
        overflow: 'hidden',
      },
      '& img': {
        height: '100%',
        width: '100%',
        // objectFit: 'cover',
      },
    },
  },
  '& .react-multi-carousel-dot-list ': {
    width: 'fit-content',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: theme.spacing(-4),
  },
  '&.HGWzThumbnails': {
    '& .react-multi-carousel-dot-list ': {
      bottom: theme.spacing(-9.375),
    },
  },
  [`& .${classes.imgContainer}`]: {
    height: '100%',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [`& .${classes.videoContainer}`]: {
    '&.MuiBox-root': {
      height: videoHeight,
      width: videoWidth,
    },
  },
}));

const ArrowLeft = (arrowLeftProps) => (
  <>
    <span {...arrowLeftProps} className="arrow arrowLeft">
      <ChevronLeftIcon />
    </span>
  </>
);

const ArrowRight = (arrowRightProps) => (
  <>
    <span {...arrowRightProps} className="arrow arrowRight">
      <ChevronRightIcon />
    </span>
  </>
);

const HeroGallery = ({
  className,
  ssrRender,
  slidesArray = [],
  fullScreen = false,
  infinite = true,
  videoWidth = '100%',
  videoHeight = '100%',
  deviceType,
  shouldResetAutoplay = true,
  thumbnails,
  sliderDots = false,
}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: fullScreen ? 0 : 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: fullScreen ? 0 : 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <Root
      height={fullScreen && '100%'}
      position="relative"
      videoWidth={videoWidth}
      videoHeight={videoHeight}
      className={`${className} ${thumbnails ? 'HGWzThumbnails' : ''}`}>
      <Carousel
        centerMode={fullScreen ? false : isTablet}
        swipeable
        ssr={ssrRender} // means to render carousel on server-side.
        infinite={infinite}
        keyBoardControl
        containerClass={`${classes.carousel} ${
          fullScreen && classes.fullScreen
        }`}
        responsive={responsive}
        minimumTouchDrag={20}
        showDots={!!fullScreen}
        customDot={thumbnails && <Thumbnails slidesArray={slidesArray} />}
        customButtonGroup={
          sliderDots && (
            <SliderDots slidesArray={slidesArray} length={slidesArray.length} />
          )
        }
        renderButtonGroupOutside={sliderDots}
        deviceType={deviceType}
        renderDotsOutside
        customTransition="transform 200ms ease-in"
        customLeftArrow={<ArrowLeft />}
        customRightArrow={<ArrowRight />}
        shouldResetAutoplay={shouldResetAutoplay}>
        {slidesArray.map((img, index) => (
          <div
            key={`Hero-Gallery-${index}-${img.youtube_id || img.urls.large}`}
            className={classes.imgContainer}>
            {img.youtube_id ? (
              <Box className={classes.videoContainer}>
                <Youtube videoId={img.youtube_id} height="100%" width="100%" />
              </Box>
            ) : (
              <Image
                className={classes.img}
                src={img.urls.large}
                alt="event gallery"
                // placeholder="blur"
                // blurDataURL={blur.src}
                layout="fill"
                objectFit="responsive"
                unoptimized
                priority={index === 0}
                objectPosition="50% 50%"
              />
            )}
          </div>
        ))}
      </Carousel>
    </Root>
  );
};

export default HeroGallery;
