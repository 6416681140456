import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Grid, alpha, Button, Dialog, IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { useTranslation } from 'next-i18next';
import 'react-lazyload-youtube/dist/index.css';
import HeroGallery from 'views/common/components/UI/HeroGallery';
import { IsVideoOrImg } from 'views/common/components/UI/GridsGallery/IsVideoOrImg';

const PREFIX = 'ToursGallery';

const classes = {
  mainContainer: `${PREFIX}-mainContainer`,
  subContainer: `${PREFIX}-subContainer`,
  subContainerItem50: `${PREFIX}-subContainerItem50`,
  subContainerItem33: `${PREFIX}-subContainerItem33`,
  galleryOpenBtn: `${PREFIX}-galleryOpenBtn`,
  galleryLabelBtn: `${PREFIX}-galleryLabelBtn`,
  btnTxt: `${PREFIX}-btnTxt`,
  closeBtn: `${PREFIX}-closeBtn`,
  galleryDialog: `${PREFIX}-galleryDialog`,
  galleryFS: `${PREFIX}-galleryFS`,
  imgBox: `${PREFIX}-imgBox`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(Box)(({ theme, galleryHeight, slidesArray }) => ({
  [`&.${classes.mainContainer}`]: {
    height: galleryHeight.SM,
    [theme.breakpoints.up('md')]: {
      height: galleryHeight.MD,
    },
    [theme.breakpoints.up('lg')]: {
      height: slidesArray.length > 6 ? galleryHeight.LG : galleryHeight.MD,
    },
  },
  [`& .${classes.mainContainer}`]: {
    height: galleryHeight.SM,
    [theme.breakpoints.up('md')]: {
      height: galleryHeight.MD,
    },
    [theme.breakpoints.up('lg')]: {
      height: slidesArray.length > 6 ? galleryHeight.LG : galleryHeight.MD,
    },
  },
  [`& .${classes.subContainer}`]: {
    height: '100%',
    flexDirection: 'column',
    display: 'flex',
    alignContent: 'flex-start',
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },

  [`& .${classes.subContainerItem50}`]: {
    width: '100%',
    height: 'calc(50% - 4px)',
    marginBottom: '8px',
    position: 'relative',
    '&:last-child': {
      marginBottom: 0,
    },
  },

  [`& .${classes.subContainerItem33}`]: {
    width: '100%',
    height: 'calc(33.3333% - 4px)',
    marginBottom: '8px',
    position: 'relative',
    '&:last-child': {
      marginBottom: 0,
    },
  },

  [`& .${classes.galleryOpenBtn}`]: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: alpha(theme.palette.primary.main, 0.7),
    borderRadius: 0,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 1),
    },
  },

  [`& .${classes.galleryLabelBtn}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  [`& .${classes.btnTxt}`]: {
    textTransform: 'none',
    marginTop: theme.spacing(1),
    fontWeight: 700,
  },

  [`& .${classes.imgBox}`]: {
    overflow: 'hidden',
    position: 'relative',
    height: '100%',
  },
  '& .TGViewGallerBtn': {
    display: 'flex',
    flexDirection: 'column',
  },
  '& .TGPhotoLibIcon': {
    width: 30,
    height: 30,
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    '& ul': {
      '& img': {
        height: 'auto',
        width: '100%',
        objectFit: 'contain',
      },
    },
  },
  '& .MuiDialog-paperFullScreen': {
    backgroundColor: 'transparent',
  },
  [`& .${classes.closeBtn}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 9,
    backgroundColor: alpha(theme.palette.secondary.main, 0.5),
    color: theme.palette.common.fogWhite,
  },
}));

const ToursGallery = (props) => {
  const {
    ssrRender,
    deviceType,
    slidesArray = [],
    galleryHeight = { LG: '300px', MD: '550px', SM: '220px' },
    shouldResetAutoplay,
    sliderDots,
  } = props;
  const { t } = useTranslation('fe_er_common_grids_gallery');

  const [openGallery, setOpenGallery] = useState(false);

  const handleGalleryOpen = () => {
    setOpenGallery(true);
  };

  const handleGalleryClose = () => {
    setOpenGallery(false);
  };
  return (
    <>
      <Root
        className={classes.mainContainer}
        galleryHeight={galleryHeight}
        slidesArray={slidesArray}>
        {deviceType === 'mobile' && (
          <HeroGallery
            ssrRender={ssrRender}
            slidesArray={slidesArray}
            fullScreen
            infinite={false}
            deviceType={deviceType}
            shouldResetAutoplay={shouldResetAutoplay}
            sliderDots={sliderDots}
          />
        )}

        {deviceType !== 'mobile'
          && (slidesArray.length > 6 ? (
            <>
              <Grid container spacing={1} className={classes.mainContainer}>
                <Grid item xs={12} md={7}>
                  <Box className={classes.imgBox}>
                    <IsVideoOrImg slide={slidesArray[0]} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <ul className={classes.subContainer}>
                    <li className={classes.subContainerItem50}>
                      <Box className={classes.imgBox}>
                        <IsVideoOrImg slide={slidesArray[1]} />
                      </Box>
                    </li>
                    <li className={classes.subContainerItem50}>
                      <Box className={classes.imgBox}>
                        <IsVideoOrImg slide={slidesArray[2]} />
                      </Box>
                    </li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={2}>
                  <ul className={classes.subContainer}>
                    <li className={classes.subContainerItem33}>
                      <Box className={classes.imgBox}>
                        <IsVideoOrImg slide={slidesArray[3]} />
                      </Box>
                    </li>
                    <li className={classes.subContainerItem33}>
                      <Box className={classes.imgBox}>
                        <IsVideoOrImg slide={slidesArray[4]} />
                      </Box>
                    </li>
                    <li className={classes.subContainerItem33}>
                      <Box className={classes.imgBox}>
                        <IsVideoOrImg slide={slidesArray[5]} />
                      </Box>
                      <Button
                        color="primary"
                        disableElevation
                        variant="contained"
                        className="TGViewGallerBtn"
                        onClick={handleGalleryOpen}
                        classes={{
                          root: classes.galleryOpenBtn,
                          label: classes.galleryLabelBtn,
                        }}>
                        <PhotoLibraryIcon class="TGPhotoLibIcon" />
                        <span className={classes.btnTxt}>
                          {t('fe_er_common_grids_gallery:plus_photos', {
                            size: Math.floor(slidesArray.length / 10) * 10,
                          })}
                        </span>
                      </Button>
                    </li>
                  </ul>
                </Grid>
              </Grid>

              <StyledDialog
                open={openGallery}
                onClose={handleGalleryClose}
                fullScreen>
                <IconButton
                  onClick={handleGalleryClose}
                  aria-label="Cancel"
                  color="secondary"
                  className={classes.closeBtn}
                  size="large">
                  <CloseIcon />
                </IconButton>
                <HeroGallery
                  ssrRender={ssrRender}
                  slidesArray={slidesArray}
                  deviceType={deviceType}
                  fullScreen
                  videoWidth="640px"
                  videoHeight="480px"
                  shouldResetAutoplay={shouldResetAutoplay}
                  sliderDots={sliderDots}
                />
              </StyledDialog>
            </>
          ) : (
            <HeroGallery
              ssrRender={ssrRender}
              slidesArray={slidesArray}
              deviceType={deviceType}
              fullScreen
              infinite={false}
              shouldResetAutoplay={shouldResetAutoplay}
              sliderDots={sliderDots}
            />
          ))}
      </Root>
    </>
  );
};

export { ToursGallery };
